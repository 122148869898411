import * as React from 'react'
import * as styles from '../assets/styles.module.scss'
import { default as Header } from './common/header'
import { default as Footer } from './common/footer'
import { default as Heroshot } from './common/heroshot'
import { default as Contact } from './homepage/Contact'

import { default as Grid } from './work/Grid'

export default class Page extends React.Component<{}, {}> {
    public render() {
        return (
            <section className={styles.Container}>
                <Header title="Work | Juvasoft" />
                <Heroshot title={`Pure craftmanship pays off`}
                subtitle={`
                    Our work is designed to create honest, personal connections 
                    between our customers and their customers – current and future ones.`} />
                <div className={styles.Work}>
                    <header className={styles.Centered}>
                        <h1>Case Studies</h1>
                    </header>
                    <Grid />
                </div>
                <Contact />
                <Footer />
            </section>
        )
    }
}